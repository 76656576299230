import React from "react";
import "./AboutUs.css"

function AboutUs(){
    return (
        <> 
            <div className="Tittle">
                <h1>Nuestra empresa</h1>
            </div>
            <p className="AboutUs-text">
                Somos una empresa familiar cuyos integrantes han estado ligados a este rubro por largo 
                tiempo por lo que entendemos las necesidades y requerimientos del ferroaficionado y maquetista. 
                Nuestro objetivo es ofrecer las mejores marcas del rubro con un amplio stock 
                y a los mejores precios del mercado.
            </p>
        </>
    )
}
export default AboutUs
