import React from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css"
import { useRef } from "react";
import ReactWhatsapp from "react-whatsapp";

function Contact(){
    const form = useRef();
    const sendEmail = (e) =>{
        e.preventDefault();
        emailjs.sendForm("service_cikbvfa","template_frcl8yd", form.current, "_tiaGAQzgZI4ya_K6")
        .then((result) => {
            console.log(result.text);
            document.getElementById("Contact-form").reset()
        }, (error) => {
            console.log(error.text);
        });
    }
    return (
        <>
            <div className="Tittle">
                <h1>Contacto</h1>
            </div>
            <div className="Contact-wsp">
                <h3>Contactanos por WhatsApp</h3>
                <ReactWhatsapp number="+56962076513" message="Hola buenas, me gustaria cotizar algunos productos" className="wsp-button" />
            </div>
            <form ref={form} onSubmit={sendEmail} className="Contact-form" id="Contact-form">
                <h3>O dejanos un mensaje</h3>
                <label htmlFor="name">Nombre</label>
                <input name="name" type="name" autoComplete="name" id="name"></input>
                <label htmlFor="email">Mail</label>
                <input name="email" id="email" type="email"></input>
                <label htmlFor="mensaje">Mensaje</label>
                <textarea name="mensaje" id="mensaje" cols="30" rows="10"></textarea>
                <button type="submit">Enviar</button>
            </form>

        </>
    )
}
export default Contact