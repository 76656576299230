import React, { useEffect, useState} from "react";
import db from "./Firebase";
import "./AdminModal.css"
import { doc, setDoc } from "firebase/firestore";

function AdminModal(modal){
    const [data, setData] = useState({Nombre: "",Marca: "",Escala: "",Categoria: "",SKU: "",Precio: 0,Cantidad: 0,Foto: "",Descripcion: "",Visible: true});
    useEffect(()=>{
        setData(modal.modalData)
    })
    const subirProducto = async (e)=>{
        e.preventDefault()
        await setDoc(doc(db, "Productos", data.SKU),{
            Categoria: data.Categoria,
            Escala: data.Escala,
            Fotos: [data.Foto],
            Marca: data.Marca,
            Nombre: data.Nombre,
            Precio: data.Precio,
            SKU: data.SKU,
            Visible: data.Visible,
            cantidad: data.Cantidad,
            descripcion: data.Descripcion
        })
        .then(()=>{
            modal.recharge()
        })
        .catch((error) => {
            alert(error)
        });
    }
    if (modal.mostrar){
        return(
            <div className="AdminModal_container">
                <div className="AdminModal">
                    <h3>Agregar producto</h3>
                    <form onSubmit={subirProducto}>
                        <label htmlFor="Nombre">Nombre</label>
                        <input value={data.Nombre} onChange={(e)=>{modal.change({Nombre: e.target.value})}} type="text" name="Nombre" id="Nombre" required/>
                        <label htmlFor="Marca">Marca</label>
                        <input value={data.Marca} onChange={(e)=>{modal.change({Marca: e.target.value})}} type="text" name="Marca" id="Marca" required/>
                        <label htmlFor="Escal">Escala</label>
                        <input value={data.Escala} onChange={(e)=>{modal.change({Escala: e.target.value})}} type="text" name="Escala" id="Escala" required/>
                        <label htmlFor="Categoria">Categoria</label>
                        <input value={data.Categoria} onChange={(e)=>{modal.change({Categoria: e.target.value})}} type="text" name="Categoria" id="Categoria" required/>
                        <label htmlFor="SKU">SKU</label>
                        <input value={data.SKU} onChange={(e)=>{modal.change({SKU: e.target.value})}} type="text" name="SKU" id="SKU" required/>
                        <label  htmlFor="Precio">Precio</label>
                        <input value={data.Precio} onChange={(e)=>{modal.change({Precio: parseInt(e.target.value)})}} type="number" name="Precio" id="Precio" required/>
                        <label htmlFor="Cantidad">Cantidad</label>
                        <input value={data.Cantidad} onChange={(e)=>{modal.change({Cantidad: parseInt(e.target.value)})}} type="number" name="Cantidad" id="Cantidad" required/>
                        <label htmlFor="Foto">Link Foto</label>
                        <input value={data.Foto} onChange={(e)=>{modal.change({Foto: e.target.value})}} type="text" name="Foto" id="Foto" required/>
                        <label htmlFor="Descripcion">Descripcion</label>
                        <textarea value={data.Descripcion} onChange={(e)=>{modal.change({Descripcion: e.target.value})}} name="Descripcion" id="" cols="30" rows="10" required></textarea>
                        <label htmlFor="Visible">Visible</label>
                        <input checked={data.Visible} onChange={(e)=>{modal.change({Visible: !data.Visible})}} type="checkbox" name="Visible" id="Visible" />
                        <button onClick={modal.onshow} className="buttonRed">Cerrar</button>
                        <button type="submit" className="buttonGreen">Enviar</button>
                    </form>
                </div>
            </div>
        )
    } else{
        return(
            <>
            </>
        )
    }
}
export default AdminModal