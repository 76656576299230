import React from "react";
import { collection, getDocs } from "firebase/firestore";
import db from "../modulos/Firebase";
import AdminMostrar from "../modulos/AdminMostrar";
import "./Admin.css"
import AdminModal from "../modulos/AdminModal";
import { SHA256 } from "crypto-js";

class Admin extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            usuarios: {},
            login: false,
            modal: false,
            Nombre: "",
            Marca: "",
            Escala: "",
            Categoria: "",
            SKU: "",
            Precio: 0,
            Cantidad: 0,
            Foto: "",
            Descripcion: "",
            Visible: true,
            datos: []
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.onClick = this.onClick.bind(this)
        this.change = this.change.bind(this)
        this.fetchPost = this.fetchPost.bind(this)
        this.fetchDatos = this.fetchDatos.bind(this)
    }
    change(i){
        this.setState(i)
    }
    async fetchPost(){   
        await getDocs(collection(db, "User"))
        .then((querySnapshot)=>{              
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            this.setState({
                usuarios: newData[0]
            })
        })
    }
    async fetchDatos(){   
        await getDocs(collection(db, "Productos"))
        .then((querySnapshot)=>{              
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            this.setState({datos: newData}); 
        })
    }
    componentDidMount(){
        this.fetchPost()
        this.fetchDatos()  
    }
    componentDidUpdate(){
        if (this.state.datos.length === 0){
          this.fetchDatos();
        }
    }
    onClick(){
        this.setState({
            modal: true
        })
    }
    handleSubmit(event){
        var user = event.target[0].value
        var user = user.toLowerCase()
        var contra = event.target[1].value
        var contra = SHA256(contra).toString()
        if (this.state.usuarios.Usuario == user & this.state.usuarios.Contraseña == contra){
            this.setState({
                login: true,
            })
        }
        event.preventDefault();
    }
    render(){
        if (this.state.login){
            return(
                <>
                    <div className="Admin-menu">
                        <h2>Administrar productos</h2>
                        <button onClick={this.onClick}>agregar productos</button>
                    </div>
                    <div>
                        <AdminModal 
                            mostrar={this.state.modal} 
                            onshow={()=>{
                                this.setState({
                                    modal: false,
                                    Nombre: "",
                                    Marca: "",
                                    Escala: "",
                                    Categoria: "",
                                    SKU: "",
                                    Precio: 0,
                                    Cantidad: 0,
                                    Foto: "",
                                    Descripcion: "",
                                    Visible: true
                                });
                            }} 
                            modalData={{
                                Nombre: this.state.Nombre,
                                Marca: this.state.Marca,
                                Escala: this.state.Escala,
                                Categoria: this.state.Categoria,
                                SKU: this.state.SKU,
                                Precio: this.state.Precio,
                                Cantidad: this.state.Cantidad,
                                Foto: this.state.Foto,
                                Descripcion: this.state.Descripcion,
                                Visible: this.state.Visible
                            }} 
                            change={(i)=>{this.change(i)}}
                            recharge={async()=>{
                                this.fetchDatos()
                                this.setState({modal: false})
                            }}
                        />
                        <AdminMostrar datos={this.state.datos}  recharge={()=>{console.log("exit")}}/>
                    </div>
                </>
            )
        } else {
            return(
                <form onSubmit={this.handleSubmit} method="post" className="Admin-login">
                    <label htmlFor="Usuario">Usuario:</label>
                    <input type="text" name="Usuario" id="Usuario" required/>
                    <label htmlFor="Contraseña">Contraseña:</label>
                    <input type="password" name="Contraseña" id="Contraseña" required/>
                    <button type="submit">Entrar</button>
                </form>
            )
        }
    }
}

export default Admin