import React from 'react';
import emailjs from '@emailjs/browser';
import './Carro.css'
class Carro extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            name: "",
            email: "",
            direcction: "",
            phone: "",
            compra: false
        }
        this.nameChange = this.nameChange.bind(this)
        this.emailChange = this.emailChange.bind(this)
        this.direcctionChange = this.direcctionChange.bind(this)
        this.phoneChange = this.phoneChange.bind(this)
        this.submitEmail = this.submitEmail.bind(this)
        this.removeElement = this.removeElement.bind(this)
        this.erraseCarro = this.erraseCarro.bind(this)
    }
    nameChange(e){
        this.setState({
            name: e.target.value,
            email: this.state.email,
            direcction: this.state.direcction,
            phone: this.state.phone,
            compra: this.state.compra
        })
    }
    emailChange(e){
        this.setState({
            name: this.state.name,
            email: e.target.value,
            direcction: this.state.direcction,
            phone: this.state.phone,
            compra: this.state.compra
        })
    }
    direcctionChange(e){
        this.setState({
            name: this.state.name,
            email: this.state.email,
            direcction: e.target.value,
            phone: this.state.phone,
            compra: this.state.compra
        })
    }
    phoneChange(e){
        this.setState({
            name: this.state.name,
            email: this.state.email,
            direcction: this.state.direcction,
            phone: e.target.value,
            compra: this.state.compra
        })
    }
    erraseCarro(){
        this.props.delete()
    }
    submitEmail(e){
        e.preventDefault()
        var carrito =""
        this.props.carro.map((data)=>{
            carrito +=`
            <div style="display: flex;">
            <img style="width:150px" src="http://drive.google.com/uc?export=view&id=${data.Fotos[0]}" alt="foto producto" />
            <p>    ${data.Nombre} ${data.Marca} SKU:${data.SKU}   $ ${data.Precio}</p>
            </div>
            `
        })
        carrito += `<p>Total: $ ${this.props.carro.map(item => item.Precio).reduce((prev, curr)=>prev+curr, 0)}</p>` 
        const mailarray = {
            carro: carrito,
            name: this.state.name,
            email: this.state.email,
            direcction: this.state.direcction,
            phone: this.state.phone,
        } 
        emailjs.send("service_cikbvfa","template_ol27rho", mailarray, "_tiaGAQzgZI4ya_K6")
        .then((result) => {
            this.setState({
                name: "",
                email: "",
                direcction: "",
                phone: "",
                compra: true
            })
            this.erraseCarro()
        }, (error) => {
            alert(error.text);
        });
    }
    removeElement(){
        //funcion borrar elemento carro
    }
    render(){
        if (this.state.compra){
            return(
                <h2>Felicitaciones por tu compra</h2>
            )
        } else {
            return(
                <>
                    <div className="Tittle">
                        <h1>Carro</h1>
                    </div>
                    <div className="carroPage">
                        {
                            this.props.carro.map((data)=>(
                                <>
                                    <img src={"http://drive.google.com/uc?export=view&id=" + data.Fotos[0]} alt="foto producto" />
                                    <p>{data.Nombre}</p>
                                    <p>${data.Precio}</p>
                                    {/* span remplaza a button por ahora */}
                                    <span></span>
                                    {/* <button onClick={this.removeElement}>Sacar del Carro</button> */}
                                </>
                            ))
                        }
                        <form onSubmit={this.submitEmail}>
                            <label htmlFor="name">Nombre:</label>
                            <input value={this.state.name} onChange={this.nameChange} type="text" id='name' name='name' required/>
                            <label htmlFor="email">Email:</label>
                            <input value={this.state.email} onChange={this.emailChange} type="email" id='email' name='email' required/>
                            <label htmlFor="">Telefono:</label>
                            <input value={this.state.phone} onChange={this.phoneChange} type="number" name="phone" id="phone" required/>
                            <label htmlFor="direccion">Dirección de envio:</label>
                            <input value={this.state.direcction} onChange={this.direcctionChange} type="text" name="direccion" id="direccion" required />
                            
                            <button type="submit">Comprar</button>
                        </form>
                    </div>
                </>
            )
        }
    }
}
export default Carro